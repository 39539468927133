import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link } from "gatsby"

const CompanyInfoPage = () => {
  return (
    <Layout>
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/company-info">Company Information</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <h1 className="generalheader">The Walpole Difference</h1>
          <h3>
            The standard all other fence and outdoor structures are
            measured by.
          </h3>
          <h3>Low maintenance cellular PVC</h3>
          Homeowners, landscape architects, and builders alike can’t believe how
          much Walpole’s cellular PVC looks and feels like natural wood. The
          important difference is that advanced cellular PVC is low maintenance
          – you don’t have to repaint, repair, or replace it for years – and
          your fence and structures will look great season after season.
          <h3>Advanced manufacturing</h3>
          Walpole Outdoors is the nation’s most experienced company in cellular
          PVC fabrication, having spent a decade working closely with the
          nation’s most reputable original equipment manufacturers of cellular
          vinyl trim boards. As a result, we apply superior laminating, molding,
          and finishing techniques to every fence and outdoor structure we
          produce. Our cellular PVC is shaped, mitered, and routered using the
          same methods and tools that we have employed for generations when
          handcrafting in natural wood. This approach meets our highest
          standards of symmetry and balance, from the substantial dowels and
          joints, to the solid, aesthetically pleasing proportions.
          <h3>The finest natural wood</h3>
          Should you prefer natural over modern cellular PVC, Walpole’s wood of
          choice since our founding in 1933 has been decay-resistant northern
          white cedar. This handsome, durable wood is cut at our own mills in
          Maine. Milling to the exact size and proportion results in the
          handcrafting of great-looking products.
          <h3>Engineering and CAD technology</h3>
          Our in-house engineering department reviews every fence and structure
          order to ensure manufacturing accuracy. On more sophisticated custom
          work, we involve high- tech CAD systems to produce detailed drawings
          so that customers know exactly how their project will look when
          installed.
          <h3>One-of-a-kind inline staining</h3>
          Walpole’s advanced inline two-coat system is identical for cellular
          PVC and natural wood. We use an in-house airless spraying operation
          which helps the cellular structure of PVC become more uniform and
          smooth. This, along with sanding and drying between each coat, ensures
          quality paint coverage. Our factory finish is superior to coating
          after the fence has been installed and eliminates mess in your yard
          and garden.
          <h3>Choices in over 100 colors</h3>
          Walpole Outdoors offers you exceptional flexibility in color options,
          with more than 100 choices in vinyl safe paint from Sherwin Williams.
          All come with a 25-year warranty against cracking, peeling, and
          blistering.
          <h3>Professional installation</h3>
          Our trained employees make certain that posts are dug to the proper
          depth and then carefully tamped. Every section is made straight and
          sturdy, is accurately aligned and fastened securely. Your grounds and
          landscaping are always treated with care and respect. Walpole ships
          and provides installation nationwide through partnerships with
          installation specialists in your area.
          <h3>Custom design expertise</h3>
          Walpole’s expert staff can work from your professional drawings,
          simple sketches, photographs or ideas to create the custom fence and
          outdoor structures that perfectly complements your taste, needs, and
          home’s architecture. 
          <h3>Free design consultation</h3>
          Walpole is unique in that we offer a free design consultation where
          one of our trained professionals comes to your location, takes
          measurements, and assists you with choices in styles, designs, and
          options. To take advantage of this invaluable no-cost, no-obligation
          service, contact our store nearest you to schedule an appointment, or
          call 1-866-758-7926.
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CompanyInfoPage
